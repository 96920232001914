import { Col, Image, Layout, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";

import { useDispatch, useSelector } from "react-redux";

import Logo from "../../assets/img/logo-mobility.png";

import { Link } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import MobilityBanner from "../../assets/img/mobilitybanner.png";
import { useScroll } from "../../utils/useScroll";
import siniestroLogo from "../../assets/img/Ico_btn_portalsiniestro.svg";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import {
  getGamaMobilityBlogHeader,
  getGamaMobilityBlogSections,
  getHomeHeaderContent,
  getNavbarContent,
} from "../../store/strapi";

import Footer from "../../components/layouts/HomeLayout/Footer";
import BlogDetail from "./BlogDetail";
import { getGamaMobilityBlogContent } from "../../store/strapi/thunk";

function Blog() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const mobilityHeader = useSelector(getNavbarContent);
  const mobilityNavBar = useSelector(getHomeHeaderContent);
  const [visible, setVisible] = useState(false);
  const [postDetails, setPostDetails] = useState([]);
  const [isHome, setIsHome] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGamaMobilityBlogContent());
  }, []);

  const blogSections = useSelector(getGamaMobilityBlogSections);
  const blogHeader = useSelector(getGamaMobilityBlogHeader);

  const relatedSection = blogSections?.Card?.slice(0, 3);

  const handlePreviousPost = () => {
    console.log(postDetails);
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextPost = () => {
    console.log(postDetails);
    if (currentIndex < blogSections.Card.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/mobility") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const { scrollDirection } = useScroll();

  const styles = {
    active: {
      // visibility: "visible"
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden"
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const onClose = () => {
    setVisible(!visible);
  };
  const cleanModal = () => {
    document.getElementById("checkbox4").click();
    onClose();
  };

  const handleDetails = (data, index) => {
    window.scrollTo(0, 0);
    setCurrentIndex(index);
    setShowDetails(true);
    setPostDetails(data[index]);
  };

  const handleShowDetails = () => {
    setShowDetails(false);
  };

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [currentIndex]);

  return (
    <div>
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `50vh`,
          }}
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div
              style={{
                marginRight: biggerThan900 ? "" : "5px",
                width: biggerThan900 ? "270px" : "100px",
                paddingTop: "10px",
                paddingBottom: "10px",
                backgroundColor: "white",
                position: "fixed",
                zIndex: "999",
                marginTop: biggerThan900 ? "200px" : "120px",
                borderTopLeftRadius: biggerThan900 ? "40px" : "20px",
                borderBottomLeftRadius: biggerThan900 ? "40px" : "20px",
                borderTopRightRadius: biggerThan900 ? 0 : "20px",
                borderBottomRightRadius: biggerThan900 ? 0 : "20px",
                cursor: "pointer",
              }}
            >
              <a href="https://gamaleasing.cl/maintenance">
                <Row align="middle" justify="center">
                  <Col span={biggerThan900 ? 4 : 10}>
                    <img
                      src={siniestroLogo}
                      style={{
                        fontSize: "40px",
                        color: "#FF3200",
                      }}
                    />
                  </Col>

                  <Col
                    span={biggerThan900 ? 15 : 18}
                    style={{
                      color: "#FF3200",
                      fontSize: biggerThan900 ? "17px" : "10px",
                      margin: 0,
                      fontWeight: "700",
                      lineHeight: "20px",
                      marginLeft: "5px",
                      textAlign: biggerThan900 ? "" : "center",
                    }}
                  >
                    Declaracion de Robo y siniestros
                  </Col>
                </Row>
              </a>
            </div>
          </div>
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                blogHeader?.length === 0 || blogHeader === null
                  ? MobilityBanner
                  : blogHeader?.bannerImage
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "/"
                            ? "#FF3200"
                            : "white",
                      }}
                      to={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "/"
                          : mobilityHeader?.TopLinks[0].Hreference
                      }
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "GAMA MOBILITY"
                        : mobilityHeader?.TopLinks[0].label}
                    </Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://gamaleasing.cl/"
                          : mobilityHeader?.TopLinks[1].Hreference
                      }
                      rel="noreferrer"
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "GAMA LEASING"
                        : mobilityHeader?.TopLinks[1].label}
                    </a>
                  </li>

                  <li>
                    <a
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://gamaleasing.cl/"
                          : mobilityHeader?.TopLinks[2].Hreference
                      }
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "RENT A CAR"
                        : mobilityHeader?.TopLinks[2].label}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://www.tripp.cl/"
                          : mobilityHeader?.TopLinks[3].Hreference
                      }
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "TRIPP"
                        : mobilityHeader?.TopLinks[3].label}
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://www.tripp.cl/"
                          : mobilityHeader?.TopLinks[4].Hreference
                      }
                      rel="noreferrer"
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "GAMA USADOS"
                        : mobilityHeader?.TopLinks[4].label}
                    </a>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image
                        preview={false}
                        src={
                          mobilityHeader?.length === 0 ||
                          mobilityHeader === null
                            ? Logo
                            : mobilityHeader?.Logo?.data?.attributes?.url
                        }
                        width={biggerThan900 ? 170 : 130}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    {biggerThan900 ? (
                      <div
                        className="nav-container-buttons"
                        style={{
                          transition: "all ease-out 1s;",
                          opacity: `${visible ? "0" : "1"}`,
                          pointerEvents: `${visible ? "none" : "auto"}`,
                        }}
                      >
                        <Link
                          to={
                            mobilityHeader?.length === 0 ||
                            mobilityHeader === null
                              ? "/contactMobility"
                              : mobilityHeader?.NavButton[0]?.Hreference
                          }
                        >
                          <Button
                            onClick={() => {}}
                            className="gama-outline talk-button"
                          >
                            {mobilityHeader?.length === 0 ||
                            mobilityHeader === null
                              ? "Conversemos"
                              : mobilityHeader?.NavButton[0]?.Label}
                          </Button>
                        </Link>
                      </div>
                    ) : null}
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a
                                target="_blank"
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "https://gamaleasing.cl/branches"
                                    : mobilityHeader?.NavLinks[0].Hreference
                                }
                                rel="noreferrer"
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Sucursales"
                                  : mobilityHeader?.NavLinks[0].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#services"
                                    : mobilityHeader?.NavLinks[1].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Nuestros servicios"
                                  : mobilityHeader?.NavLinks[1].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#us"
                                    : mobilityHeader?.NavLinks[2].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Quienes Somos"
                                  : mobilityHeader?.NavLinks[2].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#benefits"
                                    : mobilityHeader?.NavLinks[3].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Beneficios"
                                  : mobilityHeader?.NavLinks[3].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#posts"
                                    : mobilityHeader?.NavLinks[4].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Publicaciones"
                                  : mobilityHeader?.NavLinks[4].label}
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                target="_blank"
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "https://gamaleasing.cl/branches"
                                    : mobilityHeader?.NavLinks[0].Hreference
                                }
                                rel="noreferrer"
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Sucursales"
                                  : mobilityHeader?.NavLinks[0].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#services"
                                    : mobilityHeader?.NavLinks[1].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Nuestros servicios"
                                  : mobilityHeader?.NavLinks[1].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#us"
                                    : mobilityHeader?.NavLinks[2].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Quienes Somos"
                                  : mobilityHeader?.NavLinks[2].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#benefits"
                                    : mobilityHeader?.NavLinks[3].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Beneficios"
                                  : mobilityHeader?.NavLinks[3].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#posts"
                                    : mobilityHeader?.NavLinks[4].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Publicaciones"
                                  : mobilityHeader?.NavLinks[4].label}
                              </a>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            id="checkbox4"
                            className="checkbox4 visuallyHidden"
                          ></input>
                          <label htmlFor="checkbox4" onClick={() => onClose()}>
                            <div className="hamburger hamburger4">
                              <span className="bar bar1"></span>
                              <span className="bar bar2"></span>
                              <span className="bar bar3"></span>
                              <span className="bar bar4"></span>
                              <span className="bar bar5"></span>
                            </div>
                          </label>

                          <div
                            className={
                              visible === true ? "modal modal-active" : "modal"
                            }
                          >
                            <div className="delimiter"></div>
                            <div className="container-modal">
                              <div
                                className="header-modal"
                                style={{ height: "55%" }}
                              >
                                <div className="nav-group">
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "https://gamaleasing.cl/branches"
                                        : mobilityHeader?.NavLinks[0].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Sucursales"
                                      : mobilityHeader?.NavLinks[0].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#services"
                                        : mobilityHeader?.NavLinks[1].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Nuestros servicios"
                                      : mobilityHeader?.NavLinks[1].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#us"
                                        : mobilityHeader?.NavLinks[2].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Quienes Somos"
                                      : mobilityHeader?.NavLinks[2].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#benefits"
                                        : mobilityHeader?.NavLinks[3].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Beneficios"
                                      : mobilityHeader?.NavLinks[3].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#posts"
                                        : mobilityHeader?.NavLinks[4].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Publicaciones"
                                      : mobilityHeader?.NavLinks[4].label}
                                  </a>
                                </div>

                                <div className="button-group">
                                  <a
                                    target="_blank"
                                    href={
                                      mobilityNavBar?.length === 0 ||
                                      mobilityNavBar === null
                                        ? "/contactMobility"
                                        : mobilityNavBar?.Button[0]?.Hreference
                                    }
                                    rel="noreferrer"
                                  >
                                    <Button className="gama-outline talk-button">
                                      {mobilityNavBar?.length === 0 ||
                                      mobilityNavBar === null
                                        ? "Conversemos"
                                        : mobilityNavBar?.Button[0]?.Label}
                                    </Button>
                                  </a>
                                </div>
                              </div>
                              <div
                                className="footer-modal"
                                style={{
                                  height: "45%",
                                  backgroundColor: "#2D2D2D",
                                }}
                              >
                                <ul style={{ paddingBottom: "15px" }}>
                                  <li>
                                    <Link
                                      style={{
                                        color:
                                          window.location.pathname === "/"
                                            ? "#FF3200"
                                            : "white",
                                      }}
                                      to={"/"}
                                    >
                                      GAMA MOBILITY
                                    </Link>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://gamaleasing.cl/"}
                                      rel="noreferrer"
                                    >
                                      GAMA LEASING
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://www.gamarent.cl/"}
                                      rel="noreferrer"
                                    >
                                      RENT A CAR
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://www.tripp.cl/"}
                                      rel="noreferrer"
                                    >
                                      TRIPP
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://gamaleasing.cl/usados"}
                                      rel="noreferrer"
                                    >
                                      GAMA USADOS
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                color: "#FF5F00",
                fontSize: "48px",
                width: biggerThan900 ? "800px" : "100%",
                fontWeight: "700",
                lineHeight: "58px",
              }}
            >
              {blogHeader?.length === 0 || blogHeader === null
                ? "Blog"
                : blogHeader?.Title}
            </h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "32px",
                fontSize: "24px",
                fontWeight: "400",
                width: biggerThan900 ? "750px" : "100%",

                // maxWidth: biggerThan900 ? "800px" : ""
                // marginRight: biggerThan900 ? "1120px" : ""
                paddingLeft: "0",
              }}
            >
              {blogHeader?.length === 0 || blogHeader === null
                ? "Conoce todas las novedades que traemos para ti."
                : blogHeader?.Detail}
            </h3>
          </div>
        </section>
      </Layout>
      {showDetails ? (
        <Row justify="center">
          <Col span={biggerThan900 ? 12 : 22}>
            <BlogDetail
              handlePreviousPost={handlePreviousPost}
              handleNextPost={handleNextPost}
              blogSections={blogSections.Card[currentIndex]}
              handleDetails={handleDetails}
              data={postDetails}
              handleShowDetails={handleShowDetails}
              relatedSection={relatedSection}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row
            style={{ marginTop: "80px", fontWeight: "700", fontSize: "32px" }}
            justify="center"
          >
            <Col>
              {blogSections?.length === 0 || blogSections === null
                ? "¿Qué hay de nuevo?"
                : blogSections?.Title}
            </Col>
          </Row>
          <Row justify="center">
            <Row
              style={{
                display: "grid",
                gridTemplateColumns: biggerThan900
                  ? "repeat(3, 1fr)"
                  : "repeat(1, 1fr)",
                gridTemplateRows: biggerThan900
                  ? "repeat(2, 1fr)"
                  : "repeat(1, 1fr)",
                marginBottom: "100px",
              }}
            >
              {blogSections?.Card?.map((post, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: biggerThan900 ? "362px" : "90%",
                        height: biggerThan900 ? "500px" : "510px",
                        border: "1px solid #E0E0E0",
                        marginLeft: "15px",
                        marginRight: "15px",
                        marginBottom: "15px",
                        marginTop: "40px",
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        <img
                          alt={post?.Icon.data.attributes.name}
                          src={post?.Icon.data.attributes.url}
                          style={{
                            width: "100%",
                            height: "242px",
                            backgroundSize: "contain",
                            borderTopRightRadius: "10px",
                            borderTopLeftRadius: "10px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: biggerThan900 ? "18px" : "22px",
                          fontWeight: "600",
                          marginLeft: "30px",
                          marginTop: "16px",
                          lineHeight: "29px",
                          marginBottom: "16px",
                        }}
                      >
                        <Tooltip
                          title={post.Title.length >= 95 ? post.Title : ""}
                        >
                          <span
                            style={{
                              fontWeight: "600",
                              lineHeight: "29px",
                              fontSize: biggerThan900 ? "18px" : "22px",
                              minHeight: "80px",
                            }}
                          >
                            {post.Title.length >= 95
                              ? post.Title.slice(0, 92) + "..."
                              : post.Title}
                          </span>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          fontSize: biggerThan900 ? "14px" : "12px",
                          fontWeight: "400",
                          lineHeight: "18px",
                          color: "#828282",
                          width: biggerThan900 ? "302px" : "80%",
                          marginLeft: "30px",
                          minHeight: "72px",
                        }}
                      >
                        {post.Paragraph.slice(0, 130) + "..."}
                      </div>
                      <div
                        onClick={() => handleDetails(post, index)}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          color: "#FF5F00",
                          textDecoration: "underline",
                          fontSize: biggerThan900 ? "" : "12px",
                          marginTop: biggerThan900 ? "5px" : "",
                          paddingBottom: "15px",
                          cursor: "pointer",
                          width: "95%",
                        }}
                      >{`Leer la nota completa ${">"}`}</div>
                    </div>
                  </>
                );
              })}
            </Row>
          </Row>
        </>
      )}
      <Footer logo={Logo} />
    </div>
  );
}

export default Blog;
