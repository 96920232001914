import React from "react";
import { Footer as AntFooter } from "antd/lib/layout/layout";
import {
  ArrowUpOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  PhoneFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Logo from "./../../../../assets/img/gama-logo.svg";
import Bh from "./../../../../assets/img/gama-bh.png";
import "./index.less";
import { Divider, Image, Input } from "antd";
import Button from "../../../Button";
import { HashLink } from "react-router-hash-link";
import { getFooterContent } from "../../../../store/strapi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getGamaMobilityHomeContent } from "../../../../store/strapi/thunk";
const Footer = ({ data, info, logo }) => {
  const dispatch = useDispatch();
  const footerInfo = [
    {
      title: "Sucursales",
      link: "https://gamaleasing.cl/branches/",
    },
    {
      title: "Siniestros",
      link: "https://gamaleasing.cl/portal-de-siniestros/",
    },
    {
      title: "Pagos Online",
      link: "https://gamaleasing.cl/payment/",
    },
    {
      title: "Preguntas Frecuentes",
      link: "https://gamaleasing.cl/FAQ/",
    },
    {
      title: "Publicaciones",
      link: "https://gamaleasing.cl/posts/",
    },
    {
      title: "¡Trabaja con nosotros!",
      link: "https://gamaleasing.trabajando.cl/",
    },
  ];

  useEffect(() => {
    dispatch(getGamaMobilityHomeContent());
  }, []);

  const mobilityFooter = useSelector(getFooterContent);

  const footerData = [
    {
      title: "Gama Mobility",
      link: "/mobility",
    },
    {
      title: "Gama Leasing",
      link: "https://gamaleasing.cl/",
    },
    {
      title: "Tripp",
      link: "https://www.tripp.cl/",
    },
    { title: "Rent a Car", link: "https://www.gamarent.cl/" },
    { title: "Gama Usados", link: "https://gamaleasing.cl/usados" },
    { title: "Nosotros", link: "/mobilityUS" },
  ];
  return (
    <AntFooter
      style={{ paddingTop: "40px" }}
      className="container-footer"
      id="home-layout-footer"
    >
      <div className="contain">
        <div className="footer-header">
          <div className="footer-gama-logo">
            {logo ? (
              <Image preview={false} src={logo} width={180} />
            ) : (
              <Image preview={false} src={Logo} width={180} />
            )}
          </div>
          <div className="footer-social-networks">
            <ul>
              <li>
                <a
                  href={
                    mobilityFooter?.length === 0 || mobilityFooter === null
                      ? "https://www.facebook.com/gamaleasing2013"
                      : mobilityFooter?.SocialNetwork[0]?.Hreference
                  }
                >
                  <FacebookFilled />
                </a>
              </li>
              <li>
                <a
                  href={
                    mobilityFooter?.length === 0 || mobilityFooter === null
                      ? "https://www.instagram.com/gamaleasing/"
                      : mobilityFooter?.SocialNetwork[1]?.Hreference
                  }
                >
                  <InstagramFilled />
                </a>
              </li>
              <li>
                <a
                  href={
                    mobilityFooter?.length === 0 || mobilityFooter === null
                      ? "https://www.linkedin.com/company/gamaleasing/mycompany/"
                      : mobilityFooter?.SocialNetwork[2]?.Hreference
                  }
                >
                  <LinkedinFilled />
                </a>
              </li>
              <li>
                <a
                  href={
                    mobilityFooter?.length === 0 || mobilityFooter === null
                      ? "https://www.youtube.com/channel/UC04S84vUkUYwAgRIe9t4X4Q"
                      : mobilityFooter?.SocialNetwork[3]?.Hreference
                  }
                >
                  <YoutubeFilled />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-bh-logo">
            <Image preview={false} src={Bh} width={106} />
          </div>
        </div>

        <div className="footer-about">
          <h5>
            {mobilityFooter?.length === 0 || mobilityFooter === null
              ? "Empresa"
              : mobilityFooter.FooterLinks[0]?.Title}
          </h5>
          <ul>
            {mobilityFooter?.length === 0 || mobilityFooter === null
              ? footerData?.map((item) => (
                  <li>
                    <a smooth target="_blank" href={item.link} rel="noreferrer">
                      {item.title}
                    </a>
                  </li>
                ))
              : mobilityFooter &&
                mobilityFooter?.FooterLinks[0]?.FooterLink.map((item) => (
                  <li>
                    <a
                      smooth
                      target="_blank"
                      href={item.Hreference}
                      rel="noreferrer"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
          </ul>
        </div>

        <div className="footer-information">
          <h5>
            {mobilityFooter?.length === 0 || mobilityFooter === null
              ? "Información"
              : mobilityFooter.FooterLinks[1]?.Title}
          </h5>
          <ul>
            {mobilityFooter?.length === 0 || mobilityFooter === null
              ? footerInfo?.map((item) => (
                  <li>
                    <a href={item.link} rel="noreferrer">
                      {item.title}
                    </a>
                  </li>
                ))
              : mobilityFooter &&
                mobilityFooter?.FooterLinks[1].FooterLink.map((item) => (
                  <li>
                    <a href={item.Hreference} rel="noreferrer">
                      {item.label}
                    </a>
                  </li>
                ))}
          </ul>
        </div>

        <div className="footer-callcenter">
          <h5>
            {mobilityFooter?.length === 0 || mobilityFooter === null
              ? "¿Quieres saber más?"
              : mobilityFooter?.ContactCard?.Title}
          </h5>
          <p>{"Comunícate a nuestro call center"}</p>
          <div className="phone">
            <PhoneFilled
              style={{ transform: "rotate(115deg)", marginRight: "5px" }}
            />
            <h3>600 586 8686</h3>
          </div>
        </div>

        {/* <div className="footer-newsletter">
          <h5>
            {mobilityFooter?.length === 0 || mobilityFooter === null
              ? "Suscribete a nuestros newsletter"
              : mobilityFooter?.FooterNewsletter?.Title}
          </h5>

          <form>
            <p>
              {mobilityFooter?.length === 0 || mobilityFooter === null
                ? "Conoce nuestras ofertas y promociones"
                : mobilityFooter?.FooterNewsletter?.Paragraph}
            </p>
            <Input
              placeholder={
                mobilityFooter?.length === 0 || mobilityFooter === null
                  ? "nombre@email.com"
                  : mobilityFooter?.FooterNewsletter.InputPlaceholder
              }
            />
            <Button style={{ backgroundColor: "#FF5F00", border: "none" }}>
              {mobilityFooter?.length === 0 || mobilityFooter === null
                ? "Suscribirse"
                : mobilityFooter?.FooterNewsletter?.NewsletterButton.Label}
            </Button>
          </form>
        </div> */}
      </div>

      <div className="footer-signature">
        <hr />
        <div className="signature-contain">
          <div className="footer-links">
            <a
              href={
                mobilityFooter?.length === 0 || mobilityFooter === null
                  ? "https://gamaleasing.cl/legalWarning"
                  : mobilityFooter?.BottomArea.BottomLinks[0]?.Hreference
              }
            >
              {mobilityFooter?.length === 0 || mobilityFooter === null
                ? "Avisos legales"
                : mobilityFooter?.BottomArea?.BottomLinks[0]?.label}
            </a>
            <Divider type="vertical" className="divider" />
            <a
              href={
                mobilityFooter?.length === 0 || mobilityFooter === null
                  ? "https://gamaleasing.cl/privacyPolices"
                  : mobilityFooter?.BottomArea.BottomLinks[1]?.Hreference
              }
            >
              {mobilityFooter?.length === 0 || mobilityFooter === null
                ? "Políticas de Privacidad"
                : mobilityFooter?.BottomArea.BottomLinks[1]?.label}
            </a>
            <Divider type="vertical" className="divider" />
            <a
              href={
                mobilityFooter?.length === 0 || mobilityFooter === null
                  ? "https://gamaleasing.cl/complaints"
                  : mobilityFooter?.BottomArea?.BottomLinks[2]?.Hereference
              }
            >
              {mobilityFooter?.length === 0 || mobilityFooter === null
                ? "Canal de Denuncias"
                : mobilityFooter?.BottomArea?.BottomLinks[2]?.label}
            </a>
          </div>
          <div className="all-rights-reserved">
            {mobilityFooter?.length === 0 || mobilityFooter === null
              ? "© 2022 GAMALEASING. Todos los derechos reservados."
              : mobilityFooter && mobilityFooter?.BottomArea?.Trademark}
          </div>
        </div>
      </div>
      {/* TODO: CRear componente para este boton */}
      <HashLink smooth to="#top">
        <Button
          style={{ backgroundColor: "#FF5F00", border: "none" }}
          className="arrow-up-button"
          icon={<ArrowUpOutlined />}
        />
      </HashLink>
    </AntFooter>
  );
};

export default Footer;
